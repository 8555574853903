#loader {
  width: 10px; 
  position: relative;
  animation-name: loader;
  animation-duration: 2s;  
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  top:12%
}

@keyframes loader {
  0%   {width:10px}
  10%  { width:15px}
  20%  { width:20px}
  30%  { width:25px}
  40% { width:30px}
  50%  { width:35px}
  60%  { width:30px}
  70%  { width:25px}
  80% { width:20px}
  90%  { width:15px} 
  100% { width:10px}
}