@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0px;
  padding: 0px;
  font-family: "Nunito", sans-serif;
}

/* darkcolor : #122543 */
/* salmoncolor: #FFB6B5 */
.App {
  position: relative;
}

.link {
  text-decoration: none;
  color: inherit;
}
.home {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #122543;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home .home-container {
  position: relative;
  width: 300px;
  /* height: 400px; */
  background: rgba(0, 0, 0, 0.2);
  padding: 30px 20px;
  border-radius: 10px;
}

.home .home-container .icon-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.home .home-container .icon-container .icon {
  color: #ffb6b5;
  font-size: 30px;
}
.home .home-container .title {
  position: relative;
  color: white;
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.home .home-container .description {
  position: relative;
  color: white;
  font-size: 13px;
  text-align: center;
  margin-bottom: 30px;
}

.home .home-container .action-btns-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.home .home-container .action-btns-container .action-btn {
  position: relative;
  width: 100%;
  padding: 13px;
  border-radius: 50px;
  font-size: 14px;
  border: none;
  margin-bottom: 20px;
  cursor: pointer;
  text-align: center;
}

.home .home-container .action-btns-container .action-btn.sign-in {
  background: #ffb6b5;
  color: white;
}

.home .home-container .action-btns-container .action-btn.sign-up {
  background: rgba(0, 0, 0, 0);
  color: white;
  border: 1px solid white;
}

.auth {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #122543;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth .auth-container {
  position: relative;
  width: 300px;
  /* height: 400px; */
  background: rgba(0, 0, 0, 0.2);
  padding: 30px 20px;
  border-radius: 10px;
}

.auth .auth-container .title {
  position: relative;
  color: #ffb6b5;
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.auth .auth-container label {
  position: relative;
  color: white;
  font-size: 14px;
  margin-bottom: 5px;
}

.auth .auth-container .field {
  position: relative;
  width: 90%;
  padding: 13px;
  background: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 15px;
}

.auth .auth-container .action-btns-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.auth .auth-container .action-btns-container .action-btn {
  position: relative;
  width: 100%;
  padding: 13px;
  border-radius: 50px;
  font-size: 14px;
  border: none;
  margin-bottom: 20px;
  cursor: pointer;
  text-align: center;
  background: #ffb6b5;
  color: white;
}

.chat {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
}

.chat .chat-container {
  position: relative;
  width: 350px;
  height: 500px;
  overflow-y: auto;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1), -1px -1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: auto;
  margin-top: 100px;
  padding: 0px 0px 20px 0px;
  margin-bottom: 50px;
}

.chat .chat-container .top-section {
  position: relative;
  width: 100%;
  padding: 50px 0px 0px 0px;
  background: #122543;
  border-radius: 10px 10px 0px 0px;
}

.chat .chat-container .top-section .menu-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 20px;
}

.chat .chat-container .top-section .title {
  position: relative;
  width: 100px;
  font-size: 20px;
  color: white;
  font-weight: 600;
  padding: 10px;
}

.chat .chat-container .agents-section {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
}

.chat .chat-container .agents-section .box {
  position: relative;
  width: 50px;
  margin-right: 20px;
  margin-left: 10px;
  padding: 10px 0px;
}

.chat .chat-container .agents-section .box .image-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.chat .chat-container .agents-section .box .image-container .image {
  width: 40px;
  height: 50px;
  border-radius: 50px;
}
.chat .chat-container .agents-section .box .name {
  position: relative;
  width: 100%;
  text-align: center;
  color: black;
  font-size: 13px;
}

.chat .chat-container .agents-section-divider {
  width: 90%;
  margin: auto;
  height: 5px solid lightgrey;
}

.chat .chat-container .chats-section {
  position: relative;
  width: 100%;
  /* height: 100%;
  background: grey;
  overflow-y: auto; */
  border-top: 1px solid #eee;
}

.chat .chat-container .chats-section .box {
  position: relative;
  width: 100%;
  padding: 10px 0px;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 5px;
  cursor: pointer;
}

.chat .chat-container .chats-section .box .left {
  position: relative;
  width: 20%;
}

.chat .chat-container .chats-section .box .left .image-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.chat .chat-container .chats-section .box .left .image-container .image {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.chat .chat-container .chats-section .box .right {
  position: relative;
  width: 80%;
  border-bottom: 1px solid #eee;
  padding: 10px 0px;
}

.chat .chat-container .chats-section .box .right .name {
  position: relative;
  color: black;
  font-weight: 600;
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.chat .chat-container .chats-section .box .right .latest-message {
  position: relative;
  color: black;
  font-weight: grey;
  display: block;
  font-size: 12px;
}

.chat .chat-container .chats-section .box .right .message-count {
  position: absolute;
  top: 10%;
  right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: none;
  background: #ffb6b5;
  color: #122543;
  font-size: 12px;
}

.chat .chat-container .chats-section .box .right .time {
  position: absolute;
  bottom: 10%;
  right: 10px;
  color: grey;
  font-size: 11px;
}

.messages {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
}

.messages .messages-container {
  position: relative;
  width: 350px;
  height: 500px;
  overflow-y: auto;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1), -1px -1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: auto;
  margin-top: 100px;
  padding: 0px 0px 20px 0px;
  margin-bottom: 50px;
}

.messages .messages-container .top-section {
  position: relative;
  width: 100%;
  padding: 10px 0px;
  background: #122543;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 18px;
}

.messages .messages-container .top-section .profile-container {
  position: relative;
  width: 100%;
}
.messages .messages-container .top-section .profile-container .image {
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 35px;
  float: left;
  margin-left: 10px;
  margin-right: 10px;
}

.messages .messages-container .top-section .profile-container .name {
  color: white;
  position: relative;
  font-size: 13px;
}

.messages .messages-container .messages-section {
  position: relative;
  width: 100%;
  height: 85%;
  background: white;
  overflow-y: auto;
  padding: 10px 0px;
}

.messages .messages-container .messages-section .left-box {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 10px;
}

.messages .messages-container .messages-section .left-box .image-container {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
}
.messages
  .messages-container
  .messages-section
  .left-box
  .image-container
  .image {
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 35px;
}

.messages .messages-container .messages-section .left-box .text-container {
  position: relative;
  max-width: 200px;
  padding: 5px;
  background: #eee;
  font-size: 13px;
  border-radius: 10px 10px 10px 0px;
}

.messages .messages-container .messages-section .right-box {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 10px;
  justify-content: flex-end;
}

.messages .messages-container .messages-section .right-box .text-container {
  position: relative;
  max-width: 200px;
  padding: 5px;
  background: #122543;
  color: white;
  font-size: 13px;
  border-radius: 10px 10px 0px 10px;
  margin-right: 10px;
}

.messages .messages-container .footer-section {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 50px;
  background: white;
  display: flex;
  flex-flow: row nowrap;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.messages .messages-container .footer-section .row1 {
  position: relative;
  width: 70%;
}

.messages .messages-container .footer-section .row1 .field {
  width: 100%;
  border: none;
  padding: 10px;
  font-size: 14px;
  margin-left: 10px;
  margin-top: 10px;
}

.messages .messages-container .footer-section .row2 {
  position: relative;
  width: 30%;
}

.messages .messages-container .footer-section .row2 .send-btn {
  position: relative;
  padding: 5px;
  border: none;
  font-size: 14px;
  background: #ffb6b5;
  color: #122543;
  border-radius: 3px;
  margin-left: 40px;
  margin-top: 10px;
}
/** Small screens start ***/
@media only screen and (max-width: 690px) {
  .home .home-container {
    width: 80%;
  }

  .auth .auth-container {
    width: 80%;
  }

  .chat .chat-container {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 0px;
    padding: 0px 0px 0px 0px;
  }

  .chat .chat-container .top-section {
    border-radius: 0px;
  }

  .messages .messages-container {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 0px;
    padding: 0px 0px 0px 0px;
  }

  .messages .messages-container .top-section {
    border-radius: 0px 0px 0px 0px;
  }
}
/*** Small screens end ***/
